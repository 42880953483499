import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import CallToAction from '../components/CallToAction'
import { color, fontSize, lineHeight } from '../components/shared/styles'
import Product from '../components/Product'

const TopSection = styled.section`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${(props) => props.bg || ''});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${color.white};
  height: 70vh;
  padding: 100px;

  @media screen and (max-width: 1024px) {
    padding: 50px;
  }

  h1 {
    visibility: hidden;
  }

  p {
    color: ${color.white};
    font-size: ${fontSize.md};
    line-height: ${lineHeight.md};
    margin-top: 70px;
    max-width: 400px;
  }
`

const ProductsShelf = styled.section`
  background: ${color.white};
  
  > div {
    max-width: 1260px;
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    padding: 50px 0;
    background-color: ${color.white};
    
    @media screen and (max-width: 1024px) {
      grid-gap: 40px;
      padding: 40px;
      grid-template-columns: 1fr;
    }
  }
`

const CallToActionSection = styled.section`
`

const ProductsPage = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityCta {
        nodes {
          id
          name
          text
          to
          buttonText
          bgImgUrl {
            asset {
              url
            }
          }
        }
      }
      allSanityProduct {
        nodes {
          id
          name
          performance
          subTitle
          description
          route {
            current
          }
          bgImage {
            asset {
              url
            }
          }
          images {
            asset {
              url
            }
          }
          cutsheet {
            asset {
              url
            }
          }
          footNotes
        }
      }
      allSanityProductsPage {
        nodes {
          title
          topSectionImage {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  const ctas = data?.allSanityCta?.nodes?.map(
    ({ id, name, text, to, bgImgUrl, buttonText }) => ({ id, name, text, to, bgImgUrl: bgImgUrl.asset.url, buttonText })
  )

  const products = data?.allSanityProduct?.nodes?.map(
    ({
      id,
      name,
      performance,
      subTitle,
      description,
      bgImage,
      images,
      cutsheet,
      footNotes,
      route
    }) => ({
      id,
      name,
      performance,
      subTitle,
      route: route?.current || '/',
      description,
      bgImage: bgImage.asset.url,
      images: images.map(({ asset }) => ({ url: asset.url })),
      cutsheet: cutsheet.asset.url,
      footNotes
    })
  )

  const home1Cta = ctas.find(({ name }) => name === 'home_1')

  const title = data?.allSanityProductsPage?.nodes[0]?.title;

  const sectionBg = data?.allSanityProductsPage?.nodes[0]?.topSectionImage?.asset?.url;

  return (
    <Layout>
      <Seo
        title="Productos"
        description="Distribuidores oficiales de productos Twister en Uruguay, tecnología de punta para tu cosecha. Ingresá para ver los productos que ofrecemos."
      />
      <TopSection bg={sectionBg}>
        <p>{title}</p>
      </TopSection>
        <ProductsShelf>
          <div>
            {products.map(product => <Product key={product.id} {...product} />)}
          </div>
        </ProductsShelf>
      <CallToActionSection>
        {
          home1Cta && (
            <CallToAction
              text={home1Cta.text}
              to={home1Cta.to}
              buttonText={home1Cta.buttonText}
              bgImgUrl={home1Cta.bgImgUrl}
              isBgFixed
            />
          )
        }
      </CallToActionSection>
    </Layout>
  )
}

export default ProductsPage
