import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Button from '../Button/Button'

import { color, fontFamily, fontSize, letterSpacing, lineHeight } from '../shared/styles'

const Wrapper = styled.article`
  padding: 50px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.lighterGrey};
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.94), rgba(255, 255, 255, 0.94)), url(${({ bgImage }) => bgImage || ''});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  text-align: center;
  font-family: ${fontFamily.hind};
  /* justify-content: space-between; */
  align-items: center;
  grid-gap: 20px;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    padding: 25px;
  }

  &:hover {
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${({ bgImage }) => bgImage || ''});
  }

  h2 {
    color: ${color.green};
    text-transform: uppercase;
    font-family: ${fontFamily.hind};
    letter-spacing: ${letterSpacing.large};

    @media screen and (max-width: 660px) {
      font-size: 16px;
    }
  }

  img {
    max-height: 240px;
    max-width: 240px;
    width: auto;
  }
`

const Subtitle = styled.p`
  color: ${color.black};
  font-size: ${fontSize.sm};
  line-height: ${lineHeight.sm};
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${color.darkGray};
  font-size: ${fontSize.sm};
  line-height: ${lineHeight.sm};
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 20px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
`

const Product = ({ name, performance, subTitle, bgImage, images, route }) => {
  const [isHovered, setIsHovered] = useState(false)
  const buttonRef = useRef(null)

  const handleClick = () => {
    buttonRef?.current?.click();
  }

  return (
    <Wrapper
      bgImage={bgImage}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <h2>{name}</h2>
      <Subtitle>{performance}</Subtitle>
      <img src={images[0].url} alt={name} />
      <ButtonWrapper>
        <Button ref={buttonRef} variant="green" to={`/producto${route}` || '/'} isHovered={isHovered}>+ VER MÁS</Button>
      </ButtonWrapper>
      <Description>{subTitle}</Description>
    </Wrapper>
  )
}

export default Product
